import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({theme}) => {

  return (
    <header>
      <a className="logo" href="https://process-pattern.com/" target="_blank" rel="noreferrer">
        <img src={`/icons/logo${theme === "light"?"_black":""}.svg`} alt="Process and Pattern" />
      </a>
      <div className="links">
        <div className="rotated offset">
          <Link to="/">Letterform Variations</Link>
        </div>
        <div className="rotated">
          <a href="https://www.instagram.com/process.pattern/" target="_blank" rel="noreferrer">Nigel Cottier</a>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  theme: PropTypes.string,
}

Header.defaultProps = {
  theme: ``,
}

export default Header
